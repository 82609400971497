export class NumberUtil {
  public static convertToNumberIfHasValue(value: number | string | null | undefined): number | undefined {
    if (value === null || value === undefined) {
      return;
    }

    const valueAsNumber: number = Number(value);

    if (isNaN(valueAsNumber)) {
      return;
    }

    return valueAsNumber;
  }
}
